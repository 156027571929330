<template>

    <button
      class="d-none"
      data-toggle="modal"
      data-target="#alertModal"
      ref="openModalAlert"
    ></button>

    <div
      class="modal fade"
      id="alertModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg modal-width-adjustment" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <img class="modal-image-adjustment" :src="error_icon" alt="modal-icon"/>
            <p class="">Sample is a duplicate. Do you want to proceed?</p>
          </div>
          <div class="modal-footer">
            <button
              class="btn btn-warning"
              @click="handleDuplicateAction(selectedSamples)"
            >
              Yes
            </button>
            <button
              class="btn btn-secondary"
              @click="handleDuplicateAction(oldSelectedSamples)"
            >
              No
            </button>
          </div>
        </div>
      </div>
    </div>

  <div class="import-sample mt-4 mt-lg-0">
    <h6 v-if="samples?.length !== 0">Select samples to import...</h6>
    <div class="card border-0">
      <div class="card-body p-0">
        <div class="sample-list" ref="sampleList" v-if="samples?.length > 0">
          <div
            class="sample-item"
            v-for="sample in samples"
            :key="sample.id"
          >
            <input
              type="checkbox"
              name="sample"
              class="radio"
              :value="sample"
              v-model="selectedSamples"
            />
            <div class="content">
              <p class="mb-0">
                Asset <strong>{{ sample?.location.asset }}</strong> | Field
                <strong>{{ sample?.location.field }}</strong> | Well
                <strong>{{ sample?.location.well }}</strong> | Sample
                <strong>#{{ sample?.name }}</strong>
              </p>
            </div>
          </div>
        </div>
        <div class="error-message" v-if="samples?.length === 0">
          No samples found. Please try again.
        </div>
        <br />
        <div class="table-top-right justify-content-start mt-2" v-if="samples?.length !== 0">
          <button class="btn btn-primary" @click="importData" :disabled="selectedSamples?.length === 0">
            <span v-if="btnLoading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span v-else>Begin Import</span>
          </button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";
import { mapGetters, mapState } from 'vuex';

export default {
  name: "ImportSample",
  emits: [
    'start-loading',
    'stop-loading',
    'loaded-samples',
    'close-modal',
    'show-dynamic-modal'
  ],
  props: {
    requestID: String,
    selectedFile: [String, File],
    asset: String,
  },
  computed: {
    ...mapState({
      isLoading: state => state.auth.isLoading
    }),
    ...mapGetters(['hasPermission']),
  },
  data() {
    return {
      error_icon: require("../../assets/images/modal/error.png"),
      samples: [],
      selectedSamples: [],
      oldSelectedSamples: [],
      btnLoading: false,
    };
  },
  watch: {
    requestID(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.loadSamples();
      }
    },
    selectedFile(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.uploadSampleFile();
      }
    },
    selectedSamples(newVal, oldVal) {
      this.oldSelectedSamples=oldVal;
      const duplicateSample = newVal.find(sample => sample.isDuplicate);
      if (duplicateSample) {
        var elem = this.$refs.openModalAlert;
        if (elem) {
          elem.click();
        }
      }
    },
  },
  methods: {
    handleDuplicateAction(val)
      {
          this.selectedSamples =  val;
          $("#alertModal").modal("hide");
    },
    async uploadSampleFile() {
      this.$emit('start-loading');
      try {
        const formData = new FormData();
        formData.append('file', this.selectedFile);
        formData.append('asset', this.asset);

        const response = await axios.post('/samples/import', formData, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        this.samples = (response.data.data)?response.data.data:[];
      } catch (error) {
        console.error("Error importing sample file:", error);
        this.$emit('close-modal');
      } finally {
        this.$emit('stop-loading');
      }
      this.$emit('loaded-samples',this.samples);
    },
    async loadSamples() {
      this.$emit('start-loading');
      try {
        const response = await axios.post(`/samples/importFromPVTIntelligence?requestID=${this.requestID}`);
        this.samples = (response.data.data)?response.data.data:[];
      } catch (error) {
        this.$emit('stop-loading');
        console.error("Error importing samples:", error);
        this.$emit('close-modal');
      } finally {
        this.$emit('stop-loading');
      }
      this.$emit('loaded-samples',this.samples);
    },
    async importData() {
      this.btnLoading = true;
      try {
        const sampleData = {
          data: {
            samples: this.selectedSamples
          }
        };
        const response = await axios.post('/samples', sampleData);

        if (response.data.statusCode === 400) {
          this.$emit(
            "show-dynamic-modal",
            [],
            0,
            "error",
            "",
            response.data.message,
            response.data.message,
            [{ text: "Ok", class: "btn-warning", action: "closeModal" }]
          );
          return;
        }

        if (response.data.statusCode === 200) {
          if(this.selectedSamples?.length==1 && this.selectedSamples[0]?.correctionStatus==false)
          {
            this.$emit(
              "show-dynamic-modal",
              this.selectedSamples,
              null,
              "success",
              "",
              "All Imports Successful!",
              "highlighted",
              [
                { text: "Finish", class: "btn-secondary", action: "finish" },
              ]
            );
          }
          else
          {
            const buttons = [
              { text: "Finish", class: "btn-secondary", action: "finish" }
            ];
            
            if (this.hasPermission('CorrectSamples')) {
              buttons.push({ text: "AI Correction", class: "btn-warning", action: "aiCorrection" });
            }

            this.$emit(
              "show-dynamic-modal",
              this.selectedSamples,
              null,
              "success",
              "",
              "All Imports Successful!",
              "highlighted",
              buttons
            );
          }
        } else {
          this.$emit(
            "show-dynamic-modal",
            [],
            0,
            "error",
            "",
            "Error Loading File: Import failed.",
            "Error Loading File: Import failed.",
            [{ text: "Ok", class: "btn-warning", action: "closeModal" }]
          );
        }

      } catch (error) {
        this.$emit(
          "show-dynamic-modal",
          [],
          0,
          "error",
          "",
          "Error Loading File: Network or server error.",
          "Error Loading File: Network or server error.",
          [{ text: "Ok", class: "btn-warning", action: "closeModal" }]
        );
        console.error("Error loading samples:", error);
      } finally {
        this.btnLoading = false;
      }
    }
  },
};
</script>

<style scoped>
.sample-list {
  max-height: 450px;
  overflow-y: auto;
}

.loading-indicator {
  text-align: center;
  padding: 10px;
  font-size: 14px;
  color: gray;
}
</style>
