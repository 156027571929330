<template>
  <div>
    <div class="table-container">
      <table class="table">
        <thead>
          <tr class="header-row">
            <th>Component</th>
            <th>MW</th>
            <th>Density</th>
            <th v-if="showColumn('FlashedGas_mol')">Flashed Gas (mol%)</th>
            <th v-if="showColumn('FlashedGas_wt')">Flashed Gas (wt%)</th>
            <th v-if="showColumn('FlashedLiquid_mol')">Flashed Liquid (mol%)</th>
            <th v-if="showColumn('FlashedLiquid_wt')">Flashed Liquid (wt%)</th>
            <th v-if="showColumn('SeparatorGas_mol')">Separator Gas (mol%)</th>
            <th v-if="showColumn('SeparatorGas_wt')">Separator Gas (wt%)</th>
            <th v-if="showColumn('SeparatorLiquid_mol')">Separator Liquid (mol%)</th>
            <th v-if="showColumn('SeparatorLiquid_wt')">Separator Liquid (wt%)</th>
            <th v-if="showColumn('Overall_mol')">Overall (mol%)</th>
            <th v-if="showColumn('Overall_wt')">Overall (wt%)</th>
            <th v-if="showColumn('FlashedLiquid_mol_dead')">Dead Oil (mol%)</th>
            <th v-if="showColumn('FlashedLiquid_wt_dead')">Dead Oil (wt%)</th>
            <th v-if="showColumn('Overall_mol_St')">Overall (mol%)</th>
            <th v-if="showColumn('Overall_wt_St')">Overall (wt%)</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in tableData" :key="index" :class="{ 'even-row': index % 2 === 1 }">
            <td>{{ item.component }}</td>
            <td>{{ toFixedValueOne(item.mw) }}</td>
            <td>{{ toFixedValuethree(item.density) }}</td>
            <td v-if="showColumn('FlashedGas_mol')">{{ formatValue(item.stockTankGas_MoleFraction) }}</td>
            <td v-if="showColumn('FlashedGas_wt')">{{ formatValue(item.stockTankGas_MassFraction) }}</td>
            <td v-if="showColumn('FlashedLiquid_mol')">{{ formatValue(item.stockTankOil_MoleFraction) }}</td>
            <td v-if="showColumn('FlashedLiquid_wt')">{{ formatValue(item.stockTankOil_MassFraction) }}</td>
            <td v-if="showColumn('SeparatorGas_mol')">{{ formatValue(item.separatorGas_MoleFraction) }}</td>
            <td v-if="showColumn('SeparatorGas_wt')">{{ formatValue(item.separatorGas_MassFraction) }}</td>
            <td v-if="showColumn('SeparatorLiquid_mol')">{{ formatValue(item.separatorLiquid_MoleFraction) }}</td>
            <td v-if="showColumn('SeparatorLiquid_wt')">{{ formatValue(item.separatorLiquid_MassFraction) }}</td>
            <td v-if="showColumn('Overall_mol')">{{ formatValue(item.reservoirLiquid_MoleFraction) }}</td>
            <td v-if="showColumn('Overall_wt')">{{ formatValue(item.reservoirLiquid_MassFraction) }}</td>
            <td v-if="showColumn('Overall_mol_St')">{{ formatValue(item.separatorLiquid_MoleFraction) }}</td>
            <td v-if="showColumn('Overall_wt_St')">{{ formatValue(item.separatorLiquid_MassFraction) }}</td>
            <td v-if="showColumn('FlashedLiquid_mol_dead')">{{ formatValue(item.stockTankOil_MoleFraction) }}</td>
            <td v-if="showColumn('FlashedLiquid_wt_dead')">{{ formatValue(item.stockTankOil_MassFraction) }}</td>
          </tr>
          <!-- Totals Row -->
          <tr class="total-row">
            <td>Total</td>
            <td></td>
            <td></td>
            <td v-if="showColumn('FlashedGas_mol')">{{ formatValue(calculateTotal('stockTankGas_MoleFraction')) }}</td>
            <td v-if="showColumn('FlashedGas_wt')">{{ formatValue(calculateTotal('stockTankGas_MassFraction')) }}</td>
            <td v-if="showColumn('FlashedLiquid_mol')">{{ formatValue(calculateTotal('stockTankOil_MoleFraction')) }}</td>
            <td v-if="showColumn('FlashedLiquid_wt')">{{ formatValue(calculateTotal('stockTankOil_MassFraction')) }}</td>
            <td v-if="showColumn('SeparatorGas_mol')">{{ formatValue(calculateTotal('separatorGas_MoleFraction')) }}</td>
            <td v-if="showColumn('SeparatorGas_wt')">{{ formatValue(calculateTotal('separatorGas_MassFraction')) }}</td>
            <td v-if="showColumn('SeparatorLiquid_mol')">{{ formatValue(calculateTotal('separatorLiquid_MoleFraction')) }}</td>
            <td v-if="showColumn('SeparatorLiquid_wt')">{{ formatValue(calculateTotal('separatorLiquid_MassFraction')) }}</td>
            <td v-if="showColumn('Overall_mol')">{{ formatValue(calculateTotal('reservoirLiquid_MoleFraction')) }}</td>
            <td v-if="showColumn('Overall_wt')">{{ formatValue(calculateTotal('reservoirLiquid_MassFraction')) }}</td>
            <td v-if="showColumn('Overall_mol_St')">{{ formatValue(calculateTotal('separatorLiquid_MoleFraction')) }}</td>
            <td v-if="showColumn('Overall_wt_St')">{{ formatValue(calculateTotal('separatorLiquid_MassFraction')) }}</td>
            <td v-if="showColumn('FlashedLiquid_mol_dead')">{{ formatValue(calculateTotal('stockTankOil_MoleFraction')) }}</td>
            <td v-if="showColumn('FlashedLiquid_wt_dead')">{{ formatValue(calculateTotal('stockTankOil_MassFraction')) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="table-container mt-3">
      <table class="table">
        <thead>
          <tr class="header-row">
            <th>Flashed GOR (scr/stb)</th>
            <th>STO Density (g/cc)</th>
            <th>API (deg)</th>
            <th>STO MW (g/mol)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
             <td>{{ sampleData?.flashedGOR || "N/A" }}</td>
            <td>{{ sampleData?.stoDensity || "N/A" }}</td>
            <td>{{ sampleData?.api || "N/A" }}</td>
            <td>{{ sampleData?.stoMW || "N/A" }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
export default {
  name: "TableDataComponent",
  props: {
    sampleData: Object,
    sampleType: String,
    useCorrectedData: Boolean
  },
  data() {
    return {};
  },
  computed: {
    tableData() {
      return this.useCorrectedData
        ? this.sampleData?.correctedComposition?.composition || []
        : this.sampleData?.originalComposition?.composition || [];
    },
    sampleTypeColumns() {
      const columnsMap = {
        'Physically Recombined': ['FlashedLiquid_mol', 'FlashedLiquid_wt', 'FlashedGas_mol', 'FlashedGas_wt', 'Overall_mol', 'Overall_wt'],
        'Bottom Hole': ['FlashedLiquid_mol', 'FlashedLiquid_wt', 'FlashedGas_mol', 'FlashedGas_wt', 'Overall_mol', 'Overall_wt'],
        'Mathematically Recombined': ['FlashedLiquid_mol', 'FlashedLiquid_wt', 'FlashedGas_mol', 'FlashedGas_wt', 'SeparatorLiquid_mol', 'SeparatorLiquid_wt', 'SeparatorGas_mol', 'SeparatorGas_wt', 'Overall_mol', 'Overall_wt'],
        'Separator Liquid': ['FlashedLiquid_mol', 'FlashedLiquid_wt', 'FlashedGas_mol', 'FlashedGas_wt', 'SeparatorLiquid_mol', 'SeparatorLiquid_wt', 'Overall_mol_ST', 'Overall_wt_ST'],
        'Separator Gas': ['SeparatorGas_mol', 'SeparatorGas_wt'],
        'Dead Oil': ['FlashedLiquid_mol_dead', 'FlashedLiquid_wt_dead'],
        'Wellhead': ['FlashedGas_mol', 'FlashedGas_wt', 'FlashedLiquid_mol', 'FlashedLiquid_wt', 'Overall_mol', 'Overall_wt']
      };

      return columnsMap[this.sampleType] || [];
    }
  },
  methods: {
    toFixedValueOne(value) {
      return value ? parseFloat(value).toFixed(1) : value;
    },
    toFixedValuetwo(value) {
      return value ? parseFloat(value).toFixed(2) : value;
    },
    toFixedValuethree(value) {
      return value ? parseFloat(value).toFixed(3) : value;
    },
    formatValue(value) {
      if (!value) return '0.000';
      const numValue = parseFloat(value);
      return numValue === 100 ? '100.000' : numValue.toFixed(3);
    },
    showColumn(column) {
      return this.sampleTypeColumns.includes(column);
    },
    calculateTotal(column) {
      const total = this.tableData.reduce((sum, item) => {
        return sum + (parseFloat(item[column]) || 0);
      }, 0);
      return total;
    }
  }
};
</script>

<style scoped>
.table-container {
  margin: 20px 0;
}

.table {
  width: 100%;
  border-collapse: collapse;
}

.header-row {
  background-color: #f0f0f0;
}

.even-row {
  background-color: #f9f9f9;
}

.total-row {
  font-weight: bold;
  background-color: #e0e0e0;
}
</style>
