<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#EditRole"
      ref="openModalEditRole"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="EditRole">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body pt-2">
            <div class="edit-sec">
              <div class="row no-gutters align-items-center">
              <h1 class="col-9">Edit Role</h1>
            <button
              type="button"
              class="close col-3 d-flex align-items-center justify-content-end"
              ref="EditRoleClose"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
              <div class="input-sec">
                <label for="role-title">Role Title</label>
                <input
                  v-model="role_title"
                  type="text"
                  class="form-control"
                  placeholder="Enter Role title"
                />
              </div>
              <div class="mt-3">
                <label for="permissions">Permissions</label>
                <vue3-select
                  v-model="select_permission"
                  :options="permissions"
                  multiple
                  placeholder="Select Permission(s)"
                />
              </div>
              <div class="form-check">
                <input
                  class="form-check-input custom-checkbox"
                  type="checkbox"
                  name="exampleRadios"
                  id="exampleRadios1"
                  v-model="suspendUser"
                />
                <label class="form-check-label" for="exampleRadios1">
                  Suspend User
                </label>
              </div>
            </div>
          </div>

          <!-- Modal Footer with buttons -->
          <div class="modal-footer">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" class="btn btn-main" @click="updateRole">
              Update
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "EditRoleModalComponent",
  props: {
    permissions: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  data() {
    return {
      role_id: null,
      role_title: '',
      role_description: '',
      select_permission: [],
      suspendUser: false
    };
  },
  methods: {
    openModal(role) {
      this.role_id = role.id;
      this.role_title = role.name;
      this.role_description = role.description || '';
      this.select_permission = role.permissions;
      this.suspendUser = role.suspendUser || false;
      
      var elem = this.$refs.openModalEditRole;
      if (elem) {
        elem.click();
      }
    },
    camelCasePermission(permission) {
      return permission
        .replace(/(?:^\w|[A-Z]|\b\w)/g, (word, index) =>
          word.toUpperCase()
        )
        .replace(/\s+/g, '');
    },
    async updateRole() {
  const formattedPermissions = this.select_permission.map((permission) =>
    this.camelCasePermission(permission)
  );

  const payload = {
    Data: {
      Role: {
        name: this.role_title,
        description: this.role_description || '',
        permissions: formattedPermissions,
      }
    }
  };

  try {
    const response = await axios.put(`/roles/${this.role_id}`, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    });

    // Check for the statusCode in the response data
    if (response.data.statusCode === 200) {
      // Close the modal after successful update
      this.$emit('roleUpdated');
      var elem = this.$refs.EditRoleClose;
      if (elem) {
        elem.click();
      }
    } else {
      alert("Failed to update role.");
    }
  } catch (error) {
    console.error("Error details:", error); // Log the error object for details
    alert(`An error occurred while updating the role`);
  }
}
  }
};
</script>

<style scoped>
/* Additional styles for the modal, if needed */
</style>
