<template>
  <div>
    <button class="d-none" data-toggle="modal" data-target="#alertModal" ref="openModalAlert"></button>

    <div class="modal fade" id="alertModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-lg modal-width-adjustment" role="document">
        <div class="modal-content">
          <div class="modal-body text-center">
            <img class="modal-image-adjustment" :src="error_icon" alt="modal-icon" />
            <p class="">Do you want to correct the sample?</p>
          </div>
          <div class="modal-footer">
            <button class="btn btn-warning" @click="handleRedirectAction(selectedSampleData, selectedSampleID)">
              Yes
            </button>
            <button class="btn btn-secondary" @click="handleRedirectAction(null, selectedSampleID)">
              No
            </button>
          </div>
        </div>
      </div>
    </div>

    <CorrectionModalComponent ref="correctionModal" />
    <div class="table-container mt-0">
        <table class="table">
          <thead>
            <tr class="header-row">
              <th v-for="header in tableHeaders" :key="header.name">
                <span v-if="showFilterIcon(header.name)" class="mr-2">
                  <i class="fa fa-search" @click="toggleFilterDropdown(header.name, $event)"></i>
                </span>

                {{ header.label }}
                <span @click="sortTable(header.name)">
                  <span v-if="sortColumn === header.name && sortDirection === 'asc'">
                    <i class="fa-solid fa-arrow-up ml-1"></i>
                  </span>
                  <span v-if="
                    sortColumn === header.name && sortDirection === 'desc'
                  ">
                    <i class="fa-solid fa-arrow-down ml-1"></i>
                  </span>
                  <span v-if="sortColumn !== header.name">
                    <i class="fa-solid fa-arrow-up mx-1"></i>
                    <i class="fa-solid fa-arrow-down"></i>
                  </span>
                </span>
                <div v-if="filterColumn === header.name" class="filter-dropdown-container" ref="filterDropdown">
                  <vue3-select class="filter-dropdown" v-model="selectedOption" :options="sortedFilteredOptions"
                    placeholder="Search..." @update:modelValue="applyFilterToGet(header.name, $event)"
                    @search="focusSearchInput($event)">
                    <template v-slot:no-options>
                      <div v-if="loading" class="loader-container">
                        <div class="spinner-border text-primary" role="status">
                          <span class="sr-only">Loading...</span>
                        </div>
                      </div>
                      <div v-else class="no-options">No options available</div>
                    </template>
                  </vue3-select>

                </div>

              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in tableData" :key="item.sampleID">
              <td>{{ item.sampleID }}</td>
              <td>{{ item.asset }}</td>
              <td>{{ item.field }}</td>
              <td>{{ item.well }}</td>
              <td>{{ item.reservoir }}</td>
              <td>{{ item.sampleType }}</td>
              <td>{{ item.fluidType }}</td>
              <td>{{ item.containerID }}</td>
              <td>{{ item.sampleName }}</td>
              <td>
                <div v-if="item.correctionStatus == false">❌</div>
                <div v-if="item.isCorrected" class="tick-table">✔</div>
              </td>
              <td>{{ item.importDate }}</td>
              <td>
                <div v-if="item.isAnchorPoint" class="tick-table">✔</div>
              </td>
              <td>
                <button 
                  v-if="!item.isCorrected && item.correctionStatus && !isLoading && hasPermission('CorrectSamples')" 
                  class="ai-correction-button"
                  @click="openModal(item.sampleID, item)"
                >
                  <img :src="view" alt="view" />
                  <span> AI Correction </span>
                </button>
                <button 
                  v-if="!isLoading && hasPermission('ViewSamples')"
                  class="btn btn-link p-0" 
                  @click="handleViewSummary(item.sampleID)"
                >
                  <img :src="eye" alt="view" />
                </button>

              </td>
            </tr>
          </tbody>
        </table>
    </div>
      <div class="pagination-container">
        <div class="pagination-info">
          <select v-model="itemsPerPage" @change="changeItemsPerPage" @focus="showOverlay = true"
            @blur="showOverlay = false">
            <option v-for="option in itemsPerPageOptions" :key="option" :value="option">
              {{ option }}
            </option>
          </select>
          Items per page
        </div>
        <div class="items-per-page">
          {{ (pagination.currentPage - 1) * itemsPerPage + 1 }} -
          {{
            Math.min(
              pagination.currentPage * itemsPerPage,
              pagination.totalItems
            )
          }}
          of {{ pagination.totalItems }} items
        </div>
        <div class="page-info">
          <span class="d-flex align-items-center">
            <span class="pagination-info">
              <select v-model="selectedPage" @change="changePage(selectedPage)" @focus="handleFocus" @blur="handleBlur"
                ref="pageSelect">
                <option v-for="page in pagination.totalPages" :key="page" :value="page">
                  {{ page }}
                </option>
              </select>
            </span>
            of {{ pagination.totalPages }} pages</span>
          <nav>
            <ul class="pagination justify-content-center">
              <li class="page-item" :class="{ disabled: pagination.currentPage === 1 }">
                <a class="page-link" href="#" @click.prevent="changePage(pagination.currentPage - 1)">
                  <img :src="left" alt="left-arrow" />
                </a>
              </li>
              <li class="page-item" :class="{
                disabled: pagination.currentPage === pagination.totalPages,
              }">
                <a class="page-link" href="#" @click.prevent="changePage(pagination.currentPage + 1)">
                  <img :src="right" alt="right-arrow" />
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div v-if="showOverlay" class="overlay" @click="hideOverlay"></div>
  </div>
</template>

<script>
import $ from "jquery";
import CorrectionModalComponent from "../../components/AICorrection/CorrectionModalComponent.vue";
import axios from "axios";
import { mapGetters, mapState } from 'vuex';

export default {
  name: "TableDataComponent",
  components: {
    CorrectionModalComponent
  },
  props: {
    tableData: Array,
    pagination: Object,
    sortDirection: String,
    sortColumn: String,
    filterData: Object,
  },
  data() {
    return {
      error_icon: require("../../assets/images/modal/error.png"),
      selectedSampleData: null,
      selectedSampleID: null,
      view: require("../../assets/images/table/trend-up.png"),
      eye: require("../../assets/images/table/eye.png"),
      left: require("../../assets/images/table/left.png"),
      right: require("../../assets/images/table/right.png"),
      sortAsc: require("../../assets/images/table/eye.png"),
      sortDesc: require("../../assets/images/table/eye.png"),
      sortDefault: require("../../assets/images/table/eye.png"),
      tableHeaders: [
        { name: "sampleID", label: "ID Number" },
        { name: "asset", label: "Asset" },
        { name: "field", label: "Field" },
        { name: "well", label: "Well" },
        { name: "reservoir", label: "Reservoir" },
        { name: "sampleType", label: "Sample Type" },
        { name: "fluidType", label: "Fluid Type" },
        { name: "containerID", label: "Container Id" },
        { name: "sampleName", label: "Sample" },
        { name: "isCorrected", label: "AI Corrected" },
        { name: "importDate", label: "Import Date" },
        { name: "isAnchorPoint", label: "Anchor Point Data" },
        { name: "view", label: "View" },
      ],
      newSortColumn: "",
      newSortDirection: "",
      itemsPerPage: this.pagination.itemsPerPage,
      itemsPerPageOptions: [10, 20, 30, 40],
      selectedPage: parseInt(localStorage.getItem('selectedPage')) || this.pagination.currentPage || 1,
      showOverlay: false,
      filterColumn: null,
      searchQuery: "",
      filteredOptions: [],
      selectedOption: null,
      selectedFilters: {
        asset: [],
        field: [],
        reservoir: [],
        well: [],
        sampleType: [],
        sample: []
      },
      loading: false,
    };
  },
  computed: {
    ...mapState({
      isLoading: state => state.auth.isLoading
    }),
    ...mapGetters(['hasPermission']),
    sortedFilteredOptions() {
      return [...this.filteredOptions].sort((a, b) => a.localeCompare(b));
    }
  },
  methods: {
    showFilterIcon(columnName) {
      return ["asset", "field", "well", "reservoir", "sampleType", "fluidType"].includes(columnName);
    },
    toggleFilterDropdown(column, event) {
      if (this.filterColumn === column) {
        this.filterColumn = null; // close the dropdown if it's already open
      } else {
        this.filterColumn = column; // open the dropdown
        this.fetchFilterOptions(column);

        // Give a short delay to allow the dropdown to render before focusing
        this.$nextTick(() => {
          this.focusSearchInput();
        });
      }
      event.stopPropagation();
    },
    setPositionDropdown(event) {
      const target = event.target.closest("th");
      const rect = target.getBoundingClientRect();
    },
    async fetchFilterOptions(column) {
      try {
        this.filteredOptions = [];
        this.loading = true; // Start loading
        let url = '/filter/options?';
        const params = [];

        if (this.selectedFilters.asset.length > 0) {
          params.push(`asset=[${this.selectedFilters.asset.map(a => `"${a}"`)}]`);
        } else {
          params.push('asset=');
        }

        if (this.selectedFilters.field.length > 0) {
          params.push(`field=[${this.selectedFilters.field.map(f => `"${f}"`)}]`);
        } else {
          params.push('field=');
        }

        if (this.selectedFilters.well.length > 0) {
          params.push(`well=[${this.selectedFilters.well.map(w => `"${w}"`)}]`);
        } else {
          params.push('well=');
        }

        if (this.selectedFilters.reservoir.length > 0) {
          params.push(`reservoir=[${this.selectedFilters.reservoir.map(r => `"${r}"`)}]`);
        } else {
          params.push('reservoir=');
        }

        if (this.selectedFilters.sampleType.length > 0) {
          params.push(`sampleType=[${this.selectedFilters.sampleType.map(s => `"${s}"`)}]`);
        } else {
          params.push('sampleType=');
        }

        if (this.selectedFilters.fluidType.length > 0) {
          params.push(`fluidType=[${this.selectedFilters.fluidType.map(f => `"${f}"`)}]`);
        } else {
          params.push('fluidType=');
        }

        url += params.join('&');
        const response = await axios.get(url);

        switch (column) {
          case 'asset':
            this.filteredOptions = response.data.data.locations.asset;
            break;
          case 'field':
            this.filteredOptions = response.data.data.locations.field;
            break;
          case 'well':
            this.filteredOptions = response.data.data.locations.well;
            break;
          case 'reservoir':
            this.filteredOptions = response.data.data.locations.reservoir;
            break;
          case 'sampleType':
            this.filteredOptions = response.data.data.sampleTypes;
            break;
          case 'fluidType':
            this.filteredOptions = response.data.data.fluidTypes;
            break;
          default:
            this.filteredOptions = [];
        }
      } catch (error) {
        console.error("Error fetching filter options:", error);
      } finally {
        this.loading = false; // End loading
      }
    }
    ,
    applyFilterToGet(column, option) {
      if (!option) {
        return;
      }

      switch (column) {
        case 'asset':
          if (!this.selectedFilters.asset.includes(option)) {
            this.selectedFilters.asset.push(option);
          }
          break;
        case 'field':
          if (!this.selectedFilters.field.includes(option)) {
            this.selectedFilters.field.push(option);
          }
          break;
        case 'reservoir':
          if (!this.selectedFilters.reservoir.includes(option)) {
            this.selectedFilters.reservoir.push(option);
          }
          break;
        case 'well':
          if (!this.selectedFilters.well.includes(option)) {
            this.selectedFilters.well.push(option);
          }
          break;
        case 'sampleType':
          if (!this.selectedFilters.sampleType.includes(option)) {
            this.selectedFilters.sampleType.push(option);
          }
          break;
        case 'fluidType':
          if (!this.selectedFilters.fluidType.includes(option)) {
            this.selectedFilters.fluidType.push(option);
          }
          break;
        default:
          console.warn(`No matching column found for ${column}`);
      }

      this.$emit("apply-filter-table", this.selectedFilters);
      this.filterColumn = null;
      this.searchQuery = '';
    },
    handleClickOutside(event) {
      const filterDropdown = this.$refs.filterDropdown;
      const isClickInsideDropdown = filterDropdown && Array.from(filterDropdown).some(el => el.contains(event.target));
      const isClickOnSearchIcon = event.target.classList.contains('fa-search') || event.target.closest('.fa-search');

      if (!isClickInsideDropdown && !isClickOnSearchIcon) {
        this.filterColumn = null;
      }
    },
    focusSearchInput() {
      const dropdown = this.$refs.filterDropdown;
      if (dropdown && dropdown.length) {
        const input = dropdown[0].querySelector('input'); // Adjust this line if the vue3-select input element is different
        if (input) input.focus();
      }
    },
    openModal(sampleID, item) {
      var elem = this.$refs.openModalAlert;
      if (elem) {
        elem.click();
      }
      this.selectedSampleData = { 0: item };
      this.selectedSampleID = sampleID;
    },

    handleRedirectAction(selectedSampleData, selectedSampleID) {
      if (selectedSampleData && selectedSampleID) {
        this.$refs.correctionModal.openModal(selectedSampleData, selectedSampleID);
      }
      else {
        this.$router.push(`/summary/view/${selectedSampleID}`);
      }
      $("#alertModal").modal("hide");
    },
    changePage(page) {
      if (page >= 1 && page <= this.pagination.totalPages) {
        this.$refs.pageSelect?.blur();
        this.selectedPage = page;
        localStorage.setItem('selectedPage', page);
        this.$emit("changePage", page);
        this.handleBlur();
      }
    },
    changeItemsPerPage() {
      localStorage.setItem('selectedPageSize', this.itemsPerPage);
      this.$emit("changeItemsPerPage", this.itemsPerPage);
    },
    handleViewSummary(id) {
      this.$router.push(`/summary/view/${id}`);
    },
    sortTable(column) {
      if (this.newSortColumn === column) {
        this.newSortDirection = this.sortDirection === "asc" ? "desc" : "asc";
      } else {
        this.newSortColumn = column;
        this.newSortDirection = "asc";
      }
      this.$emit("sortTable", {
        column: this.newSortColumn,
        direction: this.newSortDirection,
      });
    },
    handleFocus() {
      this.showOverlay = true;
    },
    handleBlur() {
      setTimeout(() => {
        if (!this.$refs.pageSelect.contains(document.activeElement)) {
          this.hideOverlay();
        }
      }, 100);
    },
    hideOverlay() {
      this.showOverlay = false;
    }
  },
  created() {
    document.addEventListener("click", this.handleClickOutside);
  },
  beforeDestroy() {
    document.removeEventListener("click", this.handleClickOutside);
  },
  mounted() {
    const savedPage = localStorage.getItem('selectedPageSize');
    if (savedPage) {
      this.itemsPerPage = Number(savedPage);
    }
     const lastPage = localStorage.getItem('selectedPage');
      if (lastPage) {
        this.changePage(parseInt(lastPage));
      }
  },
  watch: {
    "pagination.currentPage"(newPage) {
      this.selectedPage = newPage;
    },
    filterData: {
      immediate: true,
      handler(newFilterData) {
        if (newFilterData) {
          this.selectedFilters.asset = newFilterData.asset || [];
          this.selectedFilters.field = newFilterData.field || [];
          this.selectedFilters.reservoir = newFilterData.reservoir || [];
          this.selectedFilters.well = newFilterData.well || [];
          this.selectedFilters.sampleType = newFilterData.sampleType || [];
          this.selectedFilters.fluidType = newFilterData.fluidType || [];
          this.selectedFilters.isAnchorPoint = newFilterData.isAnchorPoint || '';
          this.selectedFilters.isCorrected = newFilterData.isCorrected || '';
        }
      }
    },
  },
};
</script>
<style scoped>
.filter-dropdown-container {
  position: absolute;
  z-index: 1000;
  min-width: 200px;
}

.filter-dropdown {
  background: white;
  width: 100%;
  border-radius: 4px;
}

.no-options {
  padding: 5px 10px;
  color: black;
  text-align: center;
}

.loader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  margin-left: 10px
}

.spinner-border {
  width: 1.5rem;
  height: 1.5rem;
}
</style>