import axios from 'axios';
import router from '../router';

const state = {
  user: null,
  token: localStorage.getItem('token') || '',
  permissions: [],
  isLoggingOut: false,
  isLoading: true
};

const mutations = {
  setUser(state, user) {
    state.user = user;
    state.permissions = user.permissions || [];
  },
  setToken(state, token) {
    state.token = token;
  },
  clearAuthData(state) {
    state.user = null;
    state.token = '';
    state.permissions = [];
  },
  setLogoutLoader(state, isLoading) {
    state.isLoggingOut = isLoading;
  },
  clearAuthData(state) {
    state.token = null;
    state.user = null;
  },
  setLoading(state, loading) {
    state.isLoading = loading;
  }
};


const actions = {
  login({ commit }, credentials) {
    return axios.post('/login', credentials)
      .then(response => {
        if (response.data.statusCode === 200) {
          const userData = response.data.data;
          commit('setUser', userData);
          commit('setToken', userData.token);
          localStorage.setItem('token', userData.token);
          axios.defaults.headers.common['Authorization'] = 'Bearer ' + userData.token;
          return true;
        } else {
          return false;
        }
      })
      .catch(() => {
        return false;
      });
  },
  async logout({ commit }) {
    commit('setLogoutLoader', true); // Start the loader

    try {
      const response = await axios.post('/logout'); // Call the logout endpoint

      if (response.status === 200) {
        commit('clearAuthData'); // Clear user data
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];

        // Redirect to login after successful logout
        router.push('/login'); // Redirect to the login page
      }
    } catch (error) {
      console.error('Logout error:', error);
      // Handle error as needed
    } finally {
      commit('setLogoutLoader', false); // Stop the loader
    }
  },
  tryAutoLogin({ commit }) {
    const token = localStorage.getItem('token');
    if (!token) {
      commit('setLoading', false);
      return;
    }

    axios.defaults.headers.common['Authorization'] = 'Bearer ' + token;

    return axios.get('/me')
      .then(response => {
        if (response.data.statusCode === 200) {
          commit('setUser', response.data.data);
          commit('setToken', token);
        } else {
          commit('clearAuthData');
          localStorage.removeItem('token');
          delete axios.defaults.headers.common['Authorization'];
          router.push('/login');
        }
      })
      .catch(() => {
        commit('clearAuthData');
        localStorage.removeItem('token');
        delete axios.defaults.headers.common['Authorization'];
        router.push('/login');
      })
      .finally(() => {
        commit('setLoading', false);
      });
  }
};

const getters = {
  isAuthenticated(state) {
    return state.token !== '';
  },
  user(state) {
    return state.user;
  },
  hasPermission: (state) => (permissionName) => {
    return state.permissions.some(permission => permission.name === permissionName);
  },
  can: (state) => (endpoint, method) => {
    return state.permissions.some(permission =>
      permission.apis.some(api =>
        api.endpoint === endpoint && api.method === method.toLowerCase()
      )
    );
  },
  isLoading: state => state.isLoading
};

export default {
  state,
  mutations,
  actions,
  getters
};
