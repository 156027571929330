<template>
    <div>
        <div class="row no-gutters">
              <FilterDataModalComponent ref="filterModal" @apply-filter="applyFilter" />
                <ImportModalComponent ref="importModal"/>
            <!-- Left  -->
            <div class="col-12 col-xl-6">
                <div class="table-top-left">
                    <div class="top-content">
                    <h6>Composition Corrections</h6>
                    </div>
                    <div class="table-top-right">
                        <button type="button" class="btn" @click="showModal" :disabled="isAnchorPoint">
                            <img :src="filter" alt="filter_data" />
                            <span>Filter Data</span>
                        </button>
                        <button type="button" class="btn reset-btn" @click="resetFilter">
                          <img :src="reset" alt="reset_data" />
                          <span>Reset</span>
                        </button>
                        <div class="data-strip" v-if="hasFieldValues">
                            
                            <div>
                                <div
                                v-for="(field, index) in fields"
                                :key="index"
                                class="data-field"
                                >
                                <div class="field-label" v-if="field.value">
                                    <span class="label">{{ field.label }}</span>
                                    <span class="value">{{ field.value }}</span>
                                </div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div>
            </div>
            <!-- Right  -->
            <div class="col-12 col-xl-6 d-flex align-items-end justify-content-end">
                <div class="table-top-right">
                <div class="form-check">
                    <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="exampleCheckbox1" v-model="isCorrected"
                    @change="aiCorrectedCLick" />
                    <label class="form-check-label" for="exampleCheckbox1">
                    Show AI Corrected
                    </label>
                </div>
                <div class="form-check">
                    <input class="form-check-input mt-0 custom-checkbox" type="checkbox" id="exampleCheckbox2" v-model="isAnchorPoint"
                    @change="anchorPointClick" />
                    <label class="form-check-label" for="exampleCheckbox2">
                    Show Anchor Points
                    </label>
                </div>
                <button 
                    type="button" 
                    class="btn"  
                    @click="showImportModal" 
                    :disabled="isLoading || !hasPermission('ImportSamples')"
                >
                    <img :src="import_data" alt="import" />
                    <span>Import</span>
                </button>
                <button 
                    type="button" 
                    class="btn"  
                    :disabled="isLoading || !hasPermission('ExportToPVTIntelligence')"
                >
                    <span class="export-sec">
                        <img :src="export_data" alt="export" />
                        <span>Export data to PVTi</span>
                    </span>
                </button>
                <button 
                    type="button" 
                    class="btn" 
                    @click="exportData" 
                    :disabled="loading || isLoading || !hasPermission('ViewSamples')"
                >
                    <span v-if="loading">
                        <span>Exporting...</span>
                    </span>
                    <span v-else class="export-sec">
                    <img :src="export_data" alt="export" />
                    <span>Export data to CSV</span>
                    </span>
                </button>
                </div>
            </div>
        </div>
    </div>
  </template>
  
  <script>

import { mapGetters, mapState } from 'vuex';
import axios from 'axios';
import FilterDataModalComponent from "./Filters/FilterDataModalComponent.vue";
import ImportModalComponent from './ImportModalComponent.vue';
  
export default {
    name: "MainTopComponent",
    components: {
      FilterDataModalComponent,
      ImportModalComponent
    }, 
    data() {
      return {
        isCorrected: false,
        isAnchorPoint: false,
        filter: require("../../assets/images/table/filter.png"),
        export_data: require("../../assets/images/table/export.png"),
        import_data: require("../../assets/images/table/import.png"),
        reset: require("../../assets/images/modal/refresh-2.png"),
        isModalVisible: false,
        loading: false,
      };
    },
    props: {
      pagination: Object,
      sortColumn: String,
      sortDirection: String,
      filterData: Object,
    },
    computed: {
      ...mapState({
        isLoading: state => state.auth.isLoading
      }),
      ...mapGetters(['hasPermission']),
    fields() {
      return [
        {
          label: "Asset",
          value: this.filterData.asset.join(', ')
        },
        {
          label: "Field",
          value: this.filterData.field.join(', ')
        },
        {
          label: "Well",
          value: this.filterData.well.join(', ')
        },
        {
          label: "Sample Type",
          value: this.filterData.sampleType.join(', ')
        },
        {
          label: "Fluid Type",
          value: this.filterData.fluidType.join(', ')
        },
        {
          label: "Sample",
          value: this.filterData.sample.join(', ')
        }
      ];
    },
    hasFieldValues() {
      return this.fields.some(field => field.value);
    }
    },
    methods: {
      showModal() {
        this.$refs.filterModal.openModal();
      },
      showImportModal() {
        this.$router.push("/sample/import/ai-correction");
      },
      applyFilter(filterData) {
        this.isCorrected = filterData.isCorrected;
        this.$emit("apply-filter", filterData);
      },
      resetFilter()
        {
        const filterData= {
            asset: [],
            field: [],
            reservoir:[],
            well: [],
            sample:[],
            sampleType:[],
            fluidType:[],
            isCorrected:null,
            isAnchorPoint:null,
            filterType:null,
        };
        this.isCorrected=false;
        this.isAnchorPoint=false;
        this.applyFilter(filterData);
        },
      aiCorrectedCLick() {
        this.$emit("isCorrected", (this.isCorrected==true)?true:'');
      },
      anchorPointClick() {
        this.$emit("isAnchorPoint", (this.isAnchorPoint==true)?true:'');
      },
      async exportData() {
        this.loading = true;
        try {
          const response = await axios.get(`samples/summary?pageSize=${this.pagination.totalItems}`,
            {
              params: {
                asset: (this.filterData.asset.length > 0) ? JSON.stringify(this.filterData.asset) : '',
                field: (this.filterData.field.length > 0) ? JSON.stringify(this.filterData.field) : '',
                well: (this.filterData.well.length > 0) ? JSON.stringify(this.filterData.well) : '',
                reservoir: (this.filterData.reservoir.length > 0) ? JSON.stringify(this.filterData.reservoir) : '',
                sampleType: (this.filterData.sampleType.length > 0) ? JSON.stringify(this.filterData.sampleType) : '',
                name: (this.filterData.sample.length > 0) ? JSON.stringify(this.filterData.sample) : '',
                isCorrected: this.filterData.isCorrected,
                isAnchorPoint: this.filterData.isAnchorPoint,
                sortColumn: (this.sortColumn) ? JSON.stringify(this.sortColumn) : '',
                sortDirection: (this.sortDirection) ? JSON.stringify(this.sortDirection) : '',
              }
            }
          );
  
          const csvData = this.convertToCSV(response.data.data);
          const blob = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
          const link = document.createElement('a');
          link.href = URL.createObjectURL(blob);
          link.setAttribute('download', 'Sample Summary.csv');
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } catch (error) {
          console.error('Error exporting data:', error);
        } finally {
          this.loading = false;
        }
      },
      convertToCSV(data) {
        const headerMapping = {
          "ID Number": "sampleID",
          "Asset": "asset", 
          "Field": "field",
          "Well": "well",
          "Reservoir": "reservoir",
          "Sample Type": "sampleType",
          "Fluid Type": "fluidType",
          "Container Id": "containerID",
          "Sample": "sampleName",
          "AI Corrected": "isCorrected",
          "Import Date": "importDate",
          "Anchor Point Data": "isAnchorPoint"
        };
  
        const headers = Object.keys(headerMapping);
  
        if (!Array.isArray(data)) {
          console.error('Expected an array but got:', data);
          return '';
        }
  
        const csvRows = [];
        csvRows.push(headers.join(','));
        data.forEach(row => {
          row.isCorrected=(row?.isCorrected==true)?'Yes':((row?.isCorrected==false)?'No':'');
          row.isAnchorPoint=(row?.isAnchorPoint==true)?'Yes':((row?.isAnchorPoint==false)?'No':'');
          const values = headers.map(header => `"${row[headerMapping[header]] || ''}"`);
          csvRows.push(values.join(','));
        });
  
        return csvRows.join('\n');
      },
    },
  };
  </script>
  
  <style scoped>
  /* Add your custom styles here */
  </style>  
