<template>
  <div>
    <ReservoirExpandComponent v-if="reservoirData?.length != 0" ref="reservoirExpandChart" :reservoirData="reservoirData" :totalItems="totalItems" />
    <div class="chart-data-fields">
      <div class="top-content mb-2">
        <h6>Reservoir</h6>
        <!-- <IconComponent /> -->
      </div>
      <!-- <div class="text-center mt-3" v-if="reservoirData.length == 0">
         <p>No chart available.</p>
      </div> -->
       <!-- <free-style-shimmer
        v-if="reservoirData.length == 0 && totalItems == 0"
        :is-loading="true"
        height="310px"
        width="100%"
        border-radius="12px"
        color="#F6F6F6"
      /> -->
        <div :ref="chartId"></div>
        <button type="button" class="btn expand-btn" @click="showModal" v-if="reservoirData.length != 0 && totalItems != 0">
          <img :src="expand" alt="expand" />
          <span>Expand</span>
        </button>
    </div>
  </div>
</template>
<script>
import Plotly from "plotly.js-dist";
import ReservoirExpandComponent from './ReservoirExpandComponent.vue';

export default {
  components: { ReservoirExpandComponent },
  name: "ReservoirComponent",
  props: {
    reservoirData: {
      type: Array,
      required: true
    },
    totalItems: {
      type: Number,
      required: true
    },
  },
  data() {
    return {
      chartId: "reservoirPieChart",
      expand: require("../../assets/images/table/expand.png"),
      total: 0,
      clickedLabel: '',
    };
  },
  watch: {
  reservoirData: {
    handler(newData) {
      if (Array.isArray(newData) && newData.length > 0) {
        // Calculate the initial total based on the `totalCorrected` values in `reservoirData`
        this.total = newData.reduce((acc, item) => acc + (item.totalCorrected || 0), 0);
        this.$nextTick(() => {
          if (this.$refs[this.chartId]) {
            this.drawChart();
          }
        });
      } else {
        this.total = 0;
        if (this.$refs[this.chartId]) {
          Plotly.purge(this.$refs[this.chartId]);
        }
      }
    },
    immediate: true,
    deep: true,
  },
  totalItems(newTotal) {
    this.total = newTotal;
    if (this.$refs[this.chartId] && this.$refs[this.chartId].data) {
      if (this.clickedLabel !== '') {
          Plotly.relayout(this.$refs[this.chartId], {
            'annotations[0].text': `<span style="font-size: 8px;">Total Records (${this.clickedLabel})</span><br>${this.total}`,
          });
        } else {
          Plotly.relayout(this.$refs[this.chartId], {
            'annotations[0].text': `<span style="font-size: 8px;">Total Records</span><br>${this.total}`,
          });
        }
        this.clickedLabel='';
      }
    }
},
  methods: {
    showModal() {
      this.$refs.reservoirExpandChart.openModal();
    },
    drawChart() {
      if (!this.$refs[this.chartId]) {
        console.error("Chart DOM element is null or undefined");
        return;
      }

      const values = this.reservoirData.map(item => item.totalCorrected);
      const labels = this.reservoirData.map(item => item.reservoir);

      const data = [
        {
          values: values,
          labels: labels,
          type: "pie",
          hole: 0.7,
          textinfo: "none",
          marker: {
            colors: ["#ff6384", "#36a2eb", "#ffce56", "#4bc0c0", "#9966ff"],
          },
        },
      ];

      const layout = {
        annotations: [
          {
            font: { size: 24 },
            showarrow: false,
            text: `<span style="font-size: 8px;">Total Records</span><br>${this.totalItems}`,
            x: 0.5,
            y: 0.5,
          },
        ],
        showlegend: true,
        legend: {
          orientation: "v",
          y: 0.5,
        },
        height: 280,
        margin: { t: 0, b: 0, l: 0, r: 60 },
      };

      const config = {
        responsive: true,
        displaylogo: false,
        modeBarButtonsToRemove: ["lasso2d", "select2d", "pan2d"],
      };

      Plotly.newPlot(this.$refs[this.chartId], data, layout, config);

      // Event listener for pie slice click
      this.$refs[this.chartId].on('plotly_click', (data) => {
        const clickedValue = data.points[0].value;
        const clickedLabel = data.points[0].label;
        this.clickedLabel = clickedLabel;
        this.handleClickOnSlice(clickedLabel, clickedValue);
      });
    },
    handleClickOnSlice(label, value) {
      const filterData = {
        asset: [],
        field: [],
        reservoir: label.split(","),
        well: [],
        sample: [],
        sampleType: [],
        isCorrected: null,
        filterType: null,
      };
      this.$emit("apply-filter-on-Dashboard", filterData);
    },
  },
};
</script>

