<template>
  <div>
    <button class="d-none" data-toggle="modal" data-target="#EditUser" ref="openModalEditUser"></button>
    <div class="modal" tabindex="-1" role="dialog" id="EditUser">
      <div class="modal-dialog modal-dialog-centered">
        <div class="modal-content">
          <!-- Modal Header -->
          <div class="row no-gutters align-items-center">
            <h1 class="modal-title col-9">Edit User</h1>
            <button
              type="button"
              class="close col-3 d-flex align-items-center justify-content-end"
              ref="EditUserClose"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body p-0 mr-0">
            <div class="edit-sec">
              <!-- Username Input -->
              <div class="input-sec">
                <label for="username">User name:</label>
                <input type="text" v-model="username" placeholder="Enter User name here" />
                <p v-if="errors.username" class="error-message">{{ errors.username }}</p>
              </div>

              <!-- Role Dropdown -->
              <div class="input-sec">
                <label for="role">Role:</label>
                <select v-model="selectedRole" class="form-control" :disabled="loadingRoles">
                  <option v-if="loadingRoles" disabled>Loading roles...</option>
                  <option v-for="role in roles" :key="role.id" :value="role.name">
                    {{ role.name }}
                  </option>
                </select>
                <p v-if="errors.role" class="error-message">{{ errors.role }}</p>
              </div>
            </div>
          </div>

          <!-- Modal Footer -->
          <div class="modal-footer p-0">
            <button type="button" class="btn cancel" data-dismiss="modal">
              Cancel
            </button>
            <button type="button" class="btn btn-main" @click="updateUser">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "EditUserModalComponent",
  props: {
    user: Object
  },
  data() {
    return {
      username: null,
      selectedRole: null, // Selected role for the user
      roles: [], // Available roles fetched from the API
      loadingRoles: false, // Track loading state of roles
      errors: {
        username: null,
        role: null
      }
    };
  },
  watch: {
    user: {
      immediate: true,
      handler(newUser) {
        if (newUser) {
          this.username = newUser.username;
          this.selectedRole = newUser.role; // Pre-select the user's current role
          this.fetchRoles(); // Fetch roles when user data is set
        }
      }
    }
  },
  methods: {
    openModal() {
      const elem = this.$refs.openModalEditUser;
      if (elem) {
        elem.click();
      }
    },
    async fetchRoles() {
      this.loadingRoles = true;
      try {
        const response = await axios.get('/roles');
        this.roles = response.data.data;
        this.loadingRoles = false;
      } catch (error) {
        console.error("Error fetching roles:", error.response?.data || error.message);
        this.loadingRoles = false;
        alert("Failed to fetch roles.");
      }
    },
    validateForm() {
      this.errors.username = null;
      this.errors.role = null;
      let isValid = true;

      // Validate username
      if (!this.username) {
        this.errors.username = "Username is required.";
        isValid = false;
      }

      // Validate role
      if (!this.selectedRole) {
        this.errors.role = "Role is required.";
        isValid = false;
      }

      return isValid;
    },
    async updateUser() {
      if (!this.validateForm()) {
        return;
      }

      try {
        const response = await axios.put(`/users/${this.user.id}`, {
          data: {
            user: {
              role: this.selectedRole
            }
          }
        });

        // Check response status code even for 200 responses
        if (response.data.statusCode >= 400) {
          // Handle error response with valid status code
          this.$emit('apiResponse', response.data);
          return;
        }

        // Emit successful update
        this.$emit('userUpdated', response.data);
        
        // Close modal on success
        const elem = this.$refs.EditUserClose;
        if (elem) {
          elem.click();
        }

      } catch (error) {
        // Handle network/server errors
        const errorResponse = {
          statusCode: error.response?.status || 500,
          message: error.response?.data?.message || "Failed to update user. Please try again.",
          data: null
        };
        this.$emit('apiResponse', errorResponse);
      }
    }
  }
};
</script>

<style scoped>
/* Optional: You can customize styles for error messages or other elements here */
</style>
