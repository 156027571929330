<template>
  <div>
    <button
      class="d-none"
      data-toggle="modal"
      data-target="#DeleteUser"
      ref="openModalDeleteUser"
    ></button>
    <div class="modal" tabindex="-1" role="dialog" id="DeleteUser">
      <div class="modal-dialog modal-md modal-dialog-centered">
        <div class="modal-content">
          <div class="modal-body p-0 mr-0">
            <img
              src="../../assets/images/modal/delete-tranparent.png"
              alt="cross"
            />
            <h1>Delete Confirmation</h1>
            <div class="role-permission-sec">
              <h4>User: <span>{{ user ? `${user.firstName} ${user.lastName}` : 'N/A' }}</span></h4>
              <h6>Email</h6>
              <p>{{ user ? user.username : 'N/A' }}</p>
              <h6>Role</h6>
              <p>{{ user ? user.role : 'N/A' }}</p>
            </div>
            <p>
              Do you really want to delete this User? <br/> This process <br/>cannot be undone.
            </p>
          </div>
          <div class="modal-footer p-0">
            <button type="button" class="btn cancel" data-dismiss="modal" @click="closeModal">
              Cancel
            </button>
            <button type="button" class="btn btn-main" @click="confirmDelete">Confirm</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  name: "DeleteUserComponent",
  data() {
    return {
      user: null // Add a property to store the user
    };
  },
  methods: {
    openModal(user) {
      this.user = user; // Set the user data
      var elem = this.$refs.openModalDeleteUser;
      if (elem) {
        elem.click();
      }
    },
    async confirmDelete() {
      try {
        const response = await axios.delete(`/users/${this.user.id}`);
        
        // Emit response to parent component
        this.$emit('userDeleted', response.data);

        // Show success message
        this.showAlert('success', 'Success!', 'User has been deleted successfully');

        // Close the modal
        this.closeModal();

      } catch (error) {
        console.error("Error deleting user:", error);
        this.showAlert('error', 'Error!', 'Failed to delete user');
        // Emit error response
        this.$emit('userDeleted', {
          statusCode: error.response?.status || 500,
          message: error.message || 'Failed to delete user'
        });
      }
    },
    closeModal() {
      // Close the modal using DOM API
      const modal = document.getElementById('DeleteUser');
      if (modal) {
        modal.classList.remove('show');
        modal.style.display = 'none';
        document.body.classList.remove('modal-open');
        const modalBackdrop = document.querySelector('.modal-backdrop');
        if (modalBackdrop) {
          modalBackdrop.remove();
        }
      }
      this.user = null; // Reset user data
    },
    showAlert(message, type = 'success', duration = 3000) {
      this.alert = {
        show: true,
        message,
        type
      };
      setTimeout(() => {
        this.alert.show = false;
      }, duration);
    },
  },
};
</script>

<style scoped>
.user-details-sec {
  padding: 15px;
  margin: 10px 0;
  background-color: #f8f9fa;
  border-radius: 5px;
}

.user-details-sec h4 {
  margin-bottom: 15px;
}

.user-details-sec h6 {
  color: #6c757d;
  margin-bottom: 5px;
}

.user-details-sec p {
  margin-bottom: 15px;
}
</style>
