<template>
  <div class="dashboard-top-left">
    <div class="container-fluid">
      <div class="row no-gutters">
        <div class="col-12 top-head-btn">
          <div class="row no-gutters custom-Dashboard">
            <h6>Dashboard</h6>
            <!-- Reset button functionality -->
            <button
              type="button"
              class="btn reset-btn main-btn"
              @click="resetFilters"
            >
              <img :src="reset" alt="reset_data" />
              <span>Reset</span>
            </button>
          </div>
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-lg-2">
          <AssetComponent
            :assetData="assetData"
            :totalItems="pagination.totalItems"
            @apply-filter-on-Dashboard="applyFilterFromPieChart"
          />
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-xl-2">
          <FieldComponent
            :fieldData="fieldData"
            :totalItems="pagination.totalItems"
            @apply-filter-on-Dashboard="applyFilterFromPieChart"
          />
        </div>
        <div class="col-12 col-lg-6 col-xl-4 pr-0 pr-lg-2 pr-xl-0">
          <ReserviorComponent
            :reservoirData="reservoirData"
            :totalItems="pagination.totalItems"
            @apply-filter-on-Dashboard="applyFilterFromPieChart"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AssetComponent from "@/components/Common/AssetComponent.vue";
import FieldComponent from "@/components/Common/FieldComponent.vue";
import ReserviorComponent from "@/components/Common/ReservoirComponent.vue";

export default {
  name: "DashboardLeftTopComponent",
  components: {
    AssetComponent,
    FieldComponent,
    ReserviorComponent,
  },
  data() {
    return {
      assetData: [],
      fieldData: [],
      reservoirData: [],
      filterData: {
        asset: [],
        field: [],
        reservoir: [],
        well: [],
        sample: [],
        sampleType: [],
      },
      pagination: {
        totalItems: 0,
        itemsPerPage: 10, // Assuming default items per page
      },
      reset: require("../../../assets/images/modal/refresh-2.png"),
      loading: false, // Add loading state to handle fetch states
    };
  },
  methods: {
    // Apply filter method from the pie chart
    async applyFilterFromPieChart(filterData) {
      this.loading = true;
      this.filterData = filterData;
      await this.fetchMetrics();
      this.$emit("update-filter", this.filterData);
      
      await this.fetchData(1); // Re-fetch data based on new filters
      
    },

    // Reset filters and fetch default data
    async resetFilters() {
      this.loading = true;
      // Clear all filter data
      this.filterData = {
        asset: [],
        field: [],
        reservoir: [],
        well: [],
        sample: [],
        sampleType: [],
        fluidType: [],
      };
      await this.fetchMetrics(); // Re-fetch metrics without filters
      this.$emit("update-filter", this.filterData); // Emit reset event if necessary
      
      await this.fetchData(1); // Fetch data without filters
      
    },

    // Fetch metrics data based on current filters
    async fetchMetrics() {
      try {
    const response = await axios.get("samples/metrics", {
      params: {
            asset:
              this.filterData.asset.length > 0
                ? JSON.stringify(this.filterData.asset)
                : "",
            field:
              this.filterData.field.length > 0
                ? JSON.stringify(this.filterData.field)
                : "",
            well:
              this.filterData.well.length > 0
                ? JSON.stringify(this.filterData.well)
                : "",
            reservoir:
              this.filterData.reservoir.length > 0
                ? JSON.stringify(this.filterData.reservoir)
                : "",
            sampleType:
              this.filterData.sampleType.length > 0
                ? JSON.stringify(this.filterData.sampleType)
                : "",
          },
    });
    if (response.data && response.data.data) {
      const data = response.data.data;
      this.assetData = data?.filter(item => item.scope === "asset");
      this.fieldData = data?.filter(item => item.scope === "field");
      this.reservoirData = data?.filter(item => item.scope === "reservoir");
      
    }
  } catch (error) {
    console.error("Error fetching metrics data:", error);
  }
    },

    // Fetch summary data based on current filters and pagination
    async fetchData(page = 1) {
      this.error = false;
      this.errorMessage = "";
      try {
        const response = await axios.get(`samples/summary`, {
          params: {
            pageNumber: page,
            pageSize: this.pagination.itemsPerPage,
            asset:
              this.filterData.asset.length > 0
                ? JSON.stringify(this.filterData.asset)
                : "",
            field:
              this.filterData.field.length > 0
                ? JSON.stringify(this.filterData.field)
                : "",
            well:
              this.filterData.well.length > 0
                ? JSON.stringify(this.filterData.well)
                : "",
            reservoir:
              this.filterData.reservoir.length > 0
                ? JSON.stringify(this.filterData.reservoir)
                : "",
            sampleType:
              this.filterData.sampleType.length > 0
                ? JSON.stringify(this.filterData.sampleType)
                : "",
          },
        });
        if (response.data) {
          const data = response.data;
        this.pagination.totalItems = data.pagination.totalRecords;
        } 
        
      } catch (error) {
        this.error = true;
        this.errorMessage = "Error fetching data";
        console.error("Error fetching data:", error);
      } finally {
        this.loading = false;
      }
    },
  },

  // Fetch data and metrics when component is mounted
  mounted() {
    this.fetchMetrics(); // Fetch initial metrics
    this.fetchData(); // Fetch summary data
    
  },
};
</script>

<style scoped>
</style>
