<template>
  <div id="scatterChart" class="px-4"></div>
</template>

<script>
import Plotly from "plotly.js-dist";

export default {
  name: "ScatterChart",
  props: {
    localSampleList: Object,
    originalSampleList: Object,
    yAxisScale: Boolean,
    switchMole: Boolean
  },
  mounted() {
    // Trigger chart creation on mount
    this.createChart();
  },
  methods: {
    createChart() {
      if (!this.localSampleList) {
        console.error("No sample data available to create the chart.");
        return;
      }

      const correctedComposition =
        this.localSampleList?.correctedComposition?.composition || [];
      const originalComposition =
        this.localSampleList?.originalComposition?.composition || [];

      const colorMapCorrected = {
        stockTankGas_MoleFraction: "#4DC671",
        stockTankOil_MoleFraction: "#2A64B4",
        separatorGas_MoleFraction: "#C74AF6",
        separatorLiquid_MoleFraction: "#180AB7",
        reservoirLiquid_MoleFraction: "#38C7C5",
        stockTankGas_MassFraction: "#4DC671",
        stockTankOil_MassFraction: "#2A64B4",
        separatorGas_MassFraction: "#C74AF6",
        separatorLiquid_MassFraction: "#180AB7",
        reservoirLiquid_MassFraction: "#38C7C5",
      };

      const colorMapUncorrected = {
        stockTankGas_MoleFraction: "#4DC671",
        stockTankOil_MoleFraction: "#76A715",
        separatorGas_MoleFraction: "#C74AF6",
        separatorLiquid_MoleFraction: "#C46CBE",
        reservoirLiquid_MoleFraction: "#BCBBD6",
        stockTankGas_MassFraction: "#4DC671",
        stockTankOil_MassFraction: "#76A715",
        separatorGas_MassFraction: "#C74AF6",
        separatorLiquid_MassFraction: "#C46CBE",
        reservoirLiquid_MassFraction: "#BCBBD6",
      };

      const shapeMapCorrected = {
        stockTankGas_MoleFraction: "circle",
        stockTankOil_MoleFraction: "square",
        separatorGas_MoleFraction: "triangle-up",
        separatorLiquid_MoleFraction: "triangle-up",
        reservoirLiquid_MoleFraction: "cross",
        stockTankGas_MassFraction: "circle",
        stockTankOil_MassFraction: "square",
        separatorGas_MassFraction: "triangle-up",
        separatorLiquid_MassFraction: "triangle-up",
        reservoirLiquid_MassFraction: "cross",
      };

      const shapeMapUncorrected = {
        stockTankGas_MoleFraction: "circle-open",
        stockTankOil_MoleFraction: "square-open",
        separatorGas_MoleFraction: "diamond-open",
        separatorLiquid_MoleFraction: "triangle-up-open",
        reservoirLiquid_MoleFraction: "cross",
        stockTankGas_MassFraction: "circle-open",
        stockTankOil_MassFraction: "square-open",
        separatorGas_MassFraction: "diamond-open",
        separatorLiquid_MassFraction: "triangle-up-open",
        reservoirLiquid_MassFraction: "cross",
      };

      const keys = new Set();
      correctedComposition.forEach((item) =>
        Object.keys(item).forEach((key) => keys.add(key))
      );
      originalComposition.forEach((item) =>
        Object.keys(item).forEach((key) => keys.add(key))
      );

      const keysArray = Array.from(keys).filter(
        (key) => key !== "component" && key !== "mw" && key !== "mw_UOM"
      );

      const data = [];
      let xAxisComponents =
        this.originalSampleList?.correctedComposition?.composition?.length > 0
          ? this.originalSampleList?.correctedComposition?.composition?.map((item) => item.component)
          : this.originalSampleList?.originalComposition?.composition?.map((item) => item.component);

      const createTrace = (
        composition,
        labelPrefix,
        colorMap,
        shapeMap,
        opacity,
        isCorrected
      ) => {
        keysArray.forEach((key) => {
          const yData = xAxisComponents.map((component) => {
            const item = composition.find((i) => i.component === component);
            return item ? item[key] : null;
          });

          const color = colorMap[key];
          const shape = shapeMap[key];
          const uom = this.localSampleList?.uom || ""; // Add a UOM if available
          
          data.push({
            x: xAxisComponents,
            y: yData,
            name: `${labelPrefix} (${key})`,
            text: yData.map((value, index) => {
              const component = xAxisComponents[index];
              return value !== null
                ? `${component}<br>${key}<br>${labelPrefix}<br>${value} ${uom}`
                : "";
            }),
            type: "scatter",
            mode: "lines+markers",
            textposition: "top center",
            marker: {
              color: color,
              opacity: opacity,
              symbol: shape,
              size: 10,
              line: {
                width: isCorrected ? 0 : 2, // Filled for corrected, outlined for uncorrected
                color: color,
              },
            },
            hovertemplate: "%{text}<extra></extra>", // Custom tooltip using `text`
          });
        });
      };

      // Create traces for corrected and uncorrected compositions with different shapes and styles
      createTrace(
        correctedComposition,
        "Corrected",
        colorMapCorrected,
        shapeMapCorrected,
        0.7,
        true
      );
      createTrace(
        originalComposition,
        "Uncorrected",
        colorMapUncorrected,
        shapeMapUncorrected,
        0.5,
        false
      );

      var ytitle = !this.switchMole ? 'Compositions (mole%)' : 'Compositions (wt%)';

      const layout = {
        title: "Corrected vs Uncorrected Compositions",
        margin: {
          l: 50,
          r: 50,
          t: 50,
          b: 50,
        },
        xaxis: {
          title: "Components",
          tickmode: "linear",
          range: data.length === 0 ? [0, 1] : undefined,
        },
        yaxis: {
          title: ytitle,
          type: this.yAxisScale ? "log" : "linear",
          range: data.length === 0 ? [0, 1] : undefined,
        },
        showlegend: false,
        modebar: {
          remove: ["toImage"],
        },
      };

      const config = {
        responsive: true,
        displayModeBar: true,
        displaylogo: false,
      };

      Plotly.newPlot("scatterChart", data, layout, config);
    },
  },
  watch: {
    localSampleList: {
      immediate: true,
      handler(newSampleData) {
        this.$nextTick(() => {
          this.createChart();
        });
      },
    },
    yAxisScale: {
      handler() {
        this.$nextTick(() => {
          this.createChart();
        });
      },
    },
  },
};
</script>

<style scoped>
/* Add any specific styles for the chart component here */
</style>
